*{
    padding: 0%;
    margin: 0;
    /* font-family: 'Courier New', Courier, monospace; */
}
button{
    line-height: 1%;
    font-family: 'Courier New', Courier, monospace;
}
button:hover{
    
     box-shadow: 10px 10px black;
    /* z-index: 0.5; */
}
body{
    height: 100vh;
}
