.box {
    margin: 70px;
}

body {
    /* background-image: radial-gradient( circle farthest-corner at 10% 20%,  rgba(249,232,51,1) 0%, rgba(250,196,59,1) 100.2% ); */
    /* background:yellow ; */
    background-color: #FF3CAC;
    background-image: linear-gradient(225deg, #FF3CAC 0%, #784BA0 50%, #2B86C5 100%);
    

    /* background-image: url('https://images.unsplash.com/flagged/photo-1593005510329-8a4035a7238f?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8eWVsbG93JTIwY29sb3J8ZW58MHx8MHx8fDA%3D width=100%'); */
    height: 100vh;
    margin: 0;
    /* display: flex; */
    justify-content: center;
    align-items: center;
    color: black;
    font-style:normal;
}
.but{
    background-color: #672feb;
    border-radius: 40%;
    font-size:smaller;
    color: #f5f5f5;
}