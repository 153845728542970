.body{
    text-align: center;
    font-size: x-large;
    font-family:'Times New Roman', Times, serif;
    background-color: black;
    color: white;
}
.card{
    background-color: rgb(247, 247, 149);
}
.but{
    padding-left: 20%;
    padding-right: 20%;
    background-color: coral;
    font-size: smaller;
}
/* .button{
    background-color: rgb(245, 186, 247);
    border-radius: 5%;
    color: rgb(27, 4, 41);
    background-size: cover;
} */
.full{
    margin-left: 35%;
}